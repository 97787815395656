<template>
    <div class="table-container">
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
        <div class="b-table-sticky-header table-responsive-true">
            <table class="table b-table table-bordered b-table-no-border-collapse">
                <thead>
                <tr>
                    <th rowspan="2" class="toggle-show" style="width: 67px">
                        <button type="button" class="btn btn-secondary" @click="collapseAll = !collapseAll;">
                            <i class="icon icon-chevron-circle" v-if="collapseAll"></i>
                            <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                        </button>
                    </th>
                    <th colspan="2">НАИМЕНОВАНИЕ</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(prgItemNew, prgItemIndxNew) of programsArrNew">
                    <tr :key="'prgItemIndx'+prgItemIndxNew" class="table-info">
                        <td class="toggle-show">
                            <button type="button" class="btn btn-secondary" @click="collapseItems(prgItemIndxNew)">
                                <i class="icon icon-chevron-circle" v-if="collapseArr[prgItemIndxNew]"></i>
                                <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                            </button>
                        </td>
                        <td colspan="8">{{ prgItemNew.name_ru }}</td>
                    </tr>
                    <template v-if="!collapseArr[prgItemIndxNew]">
                      <template v-for="(goalItemNew, goalItemIndexNew) of goalsArrNew">
                        <template v-if="goalItemNew!==null && parseInt(goalItemNew.forecastId)===parseInt(prgItemNew.forecastId) && parseInt(goalItemNew.programId)===parseInt(prgItemNew.id)">
                          <tr :key="`goalItemIndexNew${goalItemIndexNew}_${prgItemIndxNew}`" class="table-info" style="background-color: rgb(236, 247, 255);">
                            <td></td>
                            <td class="toggle-show" style="width: 67px;">
                              <button type="button" class="btn btn-secondary" @click="collapseItemsSec(goalItemIndexNew)">
                                <i class="icon icon-chevron-circle" v-if="collapseArrSec[goalItemIndexNew]"></i>
                                <i class="icon icon-chevron-circle icon-rotate-180" v-else></i>
                              </button>
                            </td>
                            <td colspan="7">{{ goalItemNew.name_ru }}</td>
                          </tr>

                          <template v-if="!collapseArrSec[goalItemIndexNew]">
                            <template v-for="(indicatorItemNew, indicatorItemIndexNew) of indicatorsArrNew">
                              <template v-if="indicatorItemNew!==null && parseInt(indicatorItemNew.forecastId)===parseInt(goalItemNew.forecastId) && parseInt(indicatorItemNew.programId)===parseInt(goalItemNew.programId) && parseInt(indicatorItemNew.goalId)===parseInt(goalItemNew.id)">
                                <tr :key="`indicatorItemIndexNew${prgItemIndxNew}_${goalItemIndexNew}_${indicatorItemIndexNew}`">
                                  <td colspan="2"></td>
                                  <td>{{ indicatorItemNew.name_ru }}: <template v-if="indicatorItemNew.planValue">{{ indicatorItemNew.planValue.toLocaleString() }}</template> <template v-if="indicatorItemNew.planValue">тыс. тенге</template></td>
                                </tr>
                                <tr :key="`indicatorItemIndexesNew${prgItemIndxNew}_${goalItemIndexNew}_${indicatorItemIndexNew}`" style="background-color: rgb(245 246 247)">
                                  <td colspan="2"></td>
                                  <td>
                                    <b-row class="enf-row">
                                      <b-col cols="12" md="6">
                                        <b-form-group class="enf-form-group" label-cols="3">
                                          <template #label>{{ description_kk }} :</template>
                                          <div v-bind:class="[allowable(indicatorItemNew.nameKk) ? descriptionNotEmpty : descriptionEmpty]" style="text-align: justify"><span
                                              class="enf-placeholder" v-if="!indicatorItemNew.nameKk">Введите описание {{ description_kk }}</span>
                                            <template v-if="indicatorItemNew.nameKk">
                                              <template v-if="indicatorItemNew.nameKk && indicatorItemNew.nameKk.length<200">{{ indicatorItemNew.nameKk }}</template>
                                              <template v-else>{{ indicatorItemNew.nameKk.slice(0, 200) }}...</template>
                                            </template>
                                          </div>
                                          <template v-if="editAccess">
                                            <i class="icon icon-pencil-edit" @click="editDescription('kk', true, indicatorItemNew, goalItemNew, prgItemNew)"></i>
                                          </template>
                                          <template v-else>
                                            <i class="icon icon-eye-open" @click="editDescription('kk', true, indicatorItemNew, goalItemNew, prgItemNew)"></i>
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-col cols="12" md="6">
                                        <b-form-group class="enf-form-group" label-cols="3">
                                          <template #label>{{ description_ru }}</template>
                                          <div v-bind:class="[allowable(indicatorItemNew.nameRu) ? descriptionNotEmpty : descriptionEmpty]" style="text-align: justify"><span
                                              class="enf-placeholder" v-if="!indicatorItemNew.nameRu">Введите описание {{ description_ru }}</span>
                                            <template v-if="indicatorItemNew.nameRu">
                                              <template v-if="indicatorItemNew.nameRu && indicatorItemNew.nameRu.length<200">{{ indicatorItemNew.nameRu }}</template>
                                              <template v-else>{{ indicatorItemNew.nameRu.slice(0, 200) }}...</template>
                                            </template>
                                          </div>
                                          <template v-if="editAccess">
                                            <i class="icon icon-pencil-edit" @click="editDescription('ru', true, indicatorItemNew, goalItemNew, prgItemNew)"></i>
                                          </template>
                                          <template v-else>
                                            <i class="icon icon-eye-open" @click="editDescription('ru', true, indicatorItemNew, goalItemNew, prgItemNew)"></i>
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                  </td>
                                </tr>
                              </template>
                            </template>
                          </template>

                        </template>
                      </template>
                    </template>
                </template>
                </tbody>
            </table>
        </div>
        <b-modal
            v-model="modalVisible"
            modal-class="enf-modal"
            size="lg"
            centered
            :title="modalTitle"
            hide-footer
        >
            <b-form-group>
                <template #label>{{ description_part }} :</template>
                <template v-if="editAccess">
                    <b-form-textarea placeholder="введите описание" v-model="descriptionArea" style="text-align: justify"></b-form-textarea>
                </template>
                <template v-else>
                    <b-form-textarea readonly v-model="descriptionArea" style="text-align: justify"></b-form-textarea>
                </template>
            </b-form-group>
            <template v-if="editAccess">
                <b-button variant="primary" @click="addDescriptionForm">Сохранить</b-button>
            </template>
            <b-button variant="light" @click="closeDescriptionForm">Отменить</b-button>
        </b-modal>
    </div>
</template>

<script>
    import {makeToast} from "@/modules/budget/bip/bip-types";
    import VueElementLoading from 'vue-element-loading';

    export default {
        name: 'ExplanatoryNoteAddTable',
        components: {
            'loading': VueElementLoading
        },
        props: {
            prgItems: {
                type: Object,
                required: true
            },
            curYear: {
                type: Object,
                required: false,
                default: () => {}
            },
            yearInPeriod: {
                type: Number,
                required: false,
                default: null
            },
            curRegion: {
                type: String,
                required: true
            },
            curAbp: {
                type: Object,
                required: false,
                default: () => {}
            },
            curGu: {
                type: Object,
                required: false,
                default: () => {}
            },
            curDataType: {
                type: Object,
                required: false,
                default: () => {}
            },
            curVersion: {
                type: Object,
                required: false,
                default: () => {}
            },
            userId: {
                type: String,
                required: false,
                default: () => {}
            },
            needSave: {
                type: Boolean,
                required: false
            },
            saveBtnPressed: {
                type: Boolean,
                required: false
            },
            editAccess: {
                type: Boolean,
                required: false
            }
        },
        watch: {
            needSave: function (value){
                if (value===true) {
                    this.$emit('needSaved', false);
                    // console.log('this.indicatorsArr: ' + JSON.stringify(this.indicatorsArrNew));
                    const arrIndicator = onlyFilledFields(this.indicatorsArrNew);
                    this.$emit('indicatorsArrNew', arrIndicator);
                }
                function onlyFilledFields(indicatorsArrNew){
                    const arr = [];
                    if (indicatorsArrNew.length>0){
                        for (const indicator of indicatorsArrNew){
                            arr.push(indicator);
                        }
                    }
                    return arr;
                }
            }
        },
        data() {
            return {
                description_ru: 'на русском',
                description_kk: 'на казахском',
                descriptionEmpty: 'text-area-block',
                descriptionNotEmpty: 'text-area-block-empty',
                ru: 'ru',
                kk: 'kk',
                targetItems: {
                    indicator: null,
                    descrId: null,
                    lang: null,
                    forecastId: null,
                    prgId: null,
                    goalId: null,
                    indicatorId: null
                },
                loading: false,
                collapseAll: true,
                saveTarget: true,
                modalVisible: false,
                modalTitle: '',
                description_part: '',
                descriptionArea: '',
                forecast_data: [],
                programsArrNew: [],
                goalsArrNew: [],
                indicatorsArrNew: [],
                forecastYears: [],
                collapseArr: [],
                collapseArrSec: [],
                collapseArrThird: []
            }
        },
        async created(){
            await this.getListOfForecastData();
            this.$watch('collapseAll', this.collapseAllFunc);
        },
        methods: {
            allowable(descItem){
                if (this.saveBtnPressed === true) {
                    this.descriptionEmpty = 'text-area-block-empty';
                    this.descriptionNotEmpty = 'text-area-block';
                  if (descItem !== null && this.editAccess) {
                        return true;
                    } else if (!this.editAccess) {
                        return true;
                    }
                }
                return false;
            },

            async getListOfForecastData(){
                const requestNoteObj = this.generateRequestNote();
                try {
                    let response = [];
                    response = await fetch('/api/explanatory-note/budget-request-note/forecast/' + requestNoteObj.abp + '/' + requestNoteObj.prg + '/' + requestNoteObj.gu + '/' + requestNoteObj.dataTypeId + '/' + requestNoteObj.year + '/' + requestNoteObj.curYear + '/' + requestNoteObj.region + '/' + requestNoteObj.variant);
                    response = await response.json();
                    if (response) {
                        // console.log('response: ' + JSON.stringify(response));
                        this.extractSmallArraysNew(response);
                        this.forecast_data = response;
                        this.collapseHide(this.collapseAll);
                        await this.getNoteTargetIndicatorFromDB();
                        this.loading = false;
                    }
                } catch (error) {
                    makeToast(this, 'danger', 'Ошибка загрузки "Целевых индикаторов"', error.toString());
                    this.loading = false;
                }
            },

            async getNoteTargetIndicatorFromDB(){
                const requestNoteObj = this.generateRequestNote();
                try {
                    let response = [];
                    response = await fetch('/api/explanatory-note/note-target-indicator/get/'+requestNoteObj.abp+'/'+requestNoteObj.prg+'/'+requestNoteObj.gu+'/'+requestNoteObj.dataTypeId+'/'+requestNoteObj.year+'/'+requestNoteObj.curYear+'/'+requestNoteObj.region+'/'+requestNoteObj.variant);
                    response = await response.json();
                    if (response) {
                        if (response.length>0){
                            this.setTargetIndicatorFromDB(response);
                        }
                    }
                } catch (error) {
                    makeToast(this, 'danger', 'Ошибка загрузки "Целевых индикаторов"', error.toString());
                    this.loading = false;
                }
            },

            setTargetIndicatorFromDB(response){
                if (response.length>0 && this.indicatorsArrNew.length>0){
                    for (const indicator of this.indicatorsArrNew){
                        for (const resp of response){
                            if (indicator.forecastId===resp.forecastId){
                                indicator.nameKk=resp.nameKk;
                                indicator.nameRu=resp.nameRu;
                            }
                        }
                    }
                }
            },

            closeDescriptionForm(){
                this.modalVisible = false;
                this.descriptionArea = '';
            },
            editDescription(lang, show, descrItem, goalItem, prgItem) {
                if (show) {
                    this.modalVisible = show;
                    if (lang === this.ru) {
                        this.description_part = getDescription(this.description_ru);
                        this.targetItems.forecastId = prgItem.forecastId;
                        this.targetItems.prgId = prgItem.id;
                        this.targetItems.goalId = goalItem.id;
                        this.targetItems.indicatorId = descrItem.id;
                        this.targetItems.lang = lang;
                        this.descriptionArea = descrItem.nameRu;
                    } else if (lang === this.kk){
                        this.description_part = getDescription(this.description_kk);
                        this.targetItems.forecastId = prgItem.forecastId;
                        this.targetItems.prgId = prgItem.id;
                        this.targetItems.goalId = goalItem.id;
                        this.targetItems.indicatorId = descrItem.id;
                        this.descriptionArea = descrItem.nameKk;
                        this.targetItems.lang = lang;
                    }
                    this.modalTitle = descrItem.name_ru;
                }

                function getDescription(word){
                    const firstPart = word.slice(0, 1);
                    const secondPart = word.slice(1, word.length);
                    return (firstPart.toUpperCase())+secondPart;
                }
            },
            addDescriptionForm(){
                const targetIndicator = this.requestTargetIndicatorObject();
                const language = {
                    'kk': this.kk,
                    'ru': this.ru
                }
                if (this.targetItems.lang === this.kk){
                    targetIndicator.nameKk = this.descriptionArea;
                    targetIndicator.lang = this.kk;
                }
                if (this.targetItems.lang === this.ru){
                    targetIndicator.nameRu = this.descriptionArea;
                    targetIndicator.lang = this.ru;
                }
                targetIndicator.forecastId = this.targetItems.forecastId;
                targetIndicator.prgId = this.targetItems.prgId;
                targetIndicator.goalId = this.targetItems.goalId;
                targetIndicator.indicatorId = this.targetItems.indicatorId;
                setValuest(this.indicatorsArrNew, targetIndicator, language);
                this.closeDescriptionForm();

                function setValuest(indicatorsArrNew, targetIndicator, language){
                    const arr = [];
                    if (indicatorsArrNew.length > 0 && targetIndicator){
                        for (const indicator of indicatorsArrNew){
                            if (indicator.id == targetIndicator.indicatorId){
                                if (targetIndicator.lang === language.kk) {
                                    indicator.nameKk = targetIndicator.nameKk;
                                } else if (targetIndicator.lang === language.ru) {
                                    indicator.nameRu = targetIndicator.nameRu;
                                }
                                indicator.programId = targetIndicator.prgId;
                                indicator.goalId = targetIndicator.goalId;
                                indicator.indicatorId = targetIndicator.indicatorId;
                            }
                        }
                    }
                    return arr;
                }
            },

            requestTargetIndicatorObject(){
                return {
                    id: null,
                    nameKk: '',
                    nameRu: '',
                    forecastId: null,
                    prgId: null,
                    goalId: null,
                    indicatorId: null,
                    lang: ''
                }
            },

            collapseHide(collapseAll){
                const prgList = this.programsArrNew;
                if (prgList.length>0){
                    for (let i = 0; i < prgList.length; i++){
                        this.collapseArr[i] = collapseAll;
                    }
                }
            },
            requestNoteObject(){
                return {
                    id: null,
                    abp: '',
                    prg: '',
                    gu: '',
                    dataTypeId: '',
                    year: '',
                    curYear: '',
                    region: '',
                    variant: '',
                    userId: '',
                    requestNoteDescriptionId: ''
                }
            },
            generateRequestNote(){
                const requestNoteObject = this.requestNoteObject();
                requestNoteObject.abp = this.curAbp.abp;
                requestNoteObject.prg = this.prgItems.prg;
                requestNoteObject.gu = this.curGu.code;
                requestNoteObject.dataTypeId = this.curDataType.code;
                requestNoteObject.year = this.yearInPeriod;
                requestNoteObject.curYear = this.curYear.year;
                requestNoteObject.region = this.curRegion;
                requestNoteObject.variant = this.curVersion.variant_uuid;
                requestNoteObject.userId = this.userId;
                return requestNoteObject;
            },

            extractSmallArraysNew(bigObj){
                this.programsArrNew = [];
                this.goalsArrNew = [];
                this.indicatorsArrNew = [];
                if (bigObj.programList.length>0 && bigObj.goalList.length>0 && bigObj.indicatorList.length>0){
                    const prg = this.getOnlyObjects(bigObj.programList);
                    this.putToArr(prg, 'program');
                    this.putToArr(bigObj.goalList, 'goal');
                    this.putToArr(bigObj.indicatorList, 'indicator');
                }
                // console.log('this.programsArrNew: ' + JSON.stringify(this.programsArrNew));
                // console.log('this.goalsArrNew: ' + JSON.stringify(this.goalsArrNew));
                // console.log('this.indicatorsArrNew: ' + JSON.stringify(this.indicatorsArrNew));
            },

            putToArr(tempArr, val){
                if (tempArr.length>0){
                    for (const item of tempArr){
                        if (val==='program'){
                            this.programsArrNew.push(item);
                        } else if (val==='goal'){
                            this.goalsArrNew.push(item);
                        } else if (val==='indicator'){
                            this.indicatorsArrNew.push(item);
                        }
                    }
                }
            },

            getOnlyObjects(arr) {
              const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item.id === current.id);
                if (!x) {
                  return enteredArr.concat([current]);
                } else {
                  return enteredArr;
                }
              }, []);
              return filteredObjectArr;
            }, //--Удалить дубликаты

            collapseAllFunc() {
                for (let i = 0; i < this.collapseArr.length; i++) {
                    this.collapseArr[i] = this.collapseAll;
                }
                this.collapseArr.push(false);
                this.collapseArr.splice(this.collapseArr.length - 1, 1);
                // -------------------------------------------------------------------//
                for (let i = 0; i < this.collapseArrSec.length; i++) {
                    this.collapseArrSec[i] = this.collapseAll;
                }
                this.collapseArrSec.push(false);
                this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
            },
            collapseItems(functItemIndx) {  // ---------Раскрытие первого уровня при нажатии на стрелку вниз
                const rowIndex = functItemIndx;
                this.collapseArr[rowIndex] = !this.collapseArr[rowIndex];
                this.collapseArr.push(false);
                this.collapseArr.splice(this.collapseArr.length - 1, 1);
            },
            collapseItemsSec(functItemIndx) {  // ---------Раскрытие второго уровня при нажатии на стрелку вниз
                const rowIndex = functItemIndx;
                this.collapseArrSec[rowIndex] = !this.collapseArrSec[rowIndex];
                this.collapseArrSec.push(false);
                this.collapseArrSec.splice(this.collapseArrSec.length - 1, 1);
            },

            makeToast(title, tostbody) {
                this.$bvToast.toast(tostbody, {
                    title: title,
                    autoHideDelay: 5000,
                    appendToast: true
                });
            } // сообщение с ошибкой
        }
    }
</script>

<style>
</style>